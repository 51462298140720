import logo from '../assets/simplice-logo-icon-creme.png';

export default function ComingSoon() {
    return (
        <div className="Coming">
            <div>
                <br></br>
                <br></br>
                <img src={logo} width={350} alt='simplice' />
            </div>

        </div>
    );
}