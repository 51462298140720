import salon from "../assets/img/salon-outside.jpeg"
import entrence from "../assets/img/entrence.jpg"
import enterier from "../assets/img/entreier.jpg"
import work from "../assets/img/work.jpeg"


export default function About() {
    return (
        <div className="About">
            <br></br>
            <div>
                <div className="text1">
                    <p>In Ras Al Khaimah,<br></br> nestled amidst opulent surroundings, <br></br>lies a beauty salon exuding sheer luxury.</p>
                </div>
                <div className="image">
                    <img src={salon} width={500} />
                </div>
            </div>
            <br></br>

            <div>

                <div className="text">
                    <p>From the moment you step in, <br></br> a symphony of indulgence embraces you, <br></br>with soft lighting and soothing music  <br></br>setting the tone for a transcendent experience.</p>
                </div>
                <div className="image">
                    <img src={entrence} width={500} />
                </div>
            </div>
            <br></br>

            <div>
                <div className="text1">
                    <p>With plush interiors adorned in regal hues,<br></br> every detail whispers elegance.</p>
                </div>
                <div className="image">
                    <img src={enterier} width={500} />
                </div>
            </div>
            <br></br>

            <div>
                <div className="text1">
                    <p>Skilled artisans, masters of their craft, <br></br> await to pamper you with bespoke treatments <br></br>tailored to elevate your beauty and soothe your soul.</p>
                </div>
                {/*<div className="image">
                    <img src={null} width={500} />
    </div>*/}
            </div>
            <br></br>

            <div>
                <div className="image">
                    <img src={work} width={500} />
                </div>
                <div className="text">
                    <p>From rejuvenating facials to exquisite <br></br>manicures and pedicures, <br></br> each service is a meticulous <br></br>blend of expertise and finesse.</p>
                </div>

            </div>
            <br></br>
            <br></br>

        </div>

    )
}