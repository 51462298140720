import './App.css';
import NavBar from './NavBar';
import ComingSoon from './pages/ComingSoon';
import About from './pages/About';
import Services from './pages/Services';
import Trainings from './pages/Trainings'
import { Route, Routes } from "react-router-dom"

function App() {

  return (
    <div className="App">
      <div>
        <NavBar />
      </div>
      <Routes>
        <Route path="/" element={<ComingSoon />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/trainings" element={<Trainings />} />
      </Routes>
    </div>
  );
}

export default App;
