import logo from './assets/simplice-memorandum-text-creme.png';
import './App.css';
import { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';

export default function NavBar() {
    const [menuOpen, setMenuOpen] = useState(false);

    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    const closeMenu = () => {
        setMenuOpen(false);
    };


    return (
        <nav className="Nav">
            <Link to="/" clLinkssNLinkme="site-title"><img src={logo} width={150} alt='simplice-logo' /></Link>

            <div className='menu' onClick={menuOpen ? closeMenu : toggleMenu}>
                <span></span>
                <span></span>
                <span></span>
            </div>

            <ul className={menuOpen ? "open" : "close"}>
                <li><NavLink to="/About" className="elem">About us</NavLink></li>
                <li><NavLink to="https://www.fresha.com/book-now/simplice-beauty-lounge-tmpdlc0m/all-offer?id=1228081&pId=1165356" className="elem">Book now</NavLink></li>

            </ul>

        </nav>
    );

}